h1 {
    font-size: 36px !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}

@font-face {
    font-family: TitilliumWeb;
    src: url('/fonts/TitilliumWeb/TitilliumWeb-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: TitilliumWeb;
    src: url('/fonts/TitilliumWeb/TitilliumWeb-Bold.ttf') format('truetype');
    font-weight: bold;
}

body {
    font-family: TitilliumWeb !important;
}

a.navbar-brand {
    margin-top: -4px;
    margin-bottom: -4px;
}
