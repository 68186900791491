@import './variables.scss';

div.-app-cookie-consent {
    display: block;
    position: fixed;
    padding: $app-footer-height;
    left: 0;
    bottom: 0;
    background-color: #000;
    color: #FFF;
    text-align: center;
    width: 100%;
    z-index: 99999;
}

div.-app-cookie-consent a {
    margin-left: 2em;
}
