@import './variables.scss';

div.-app-footer {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: $app-footer-height;
    padding: 5px 15px;
    font-size: 14px;
    background-color: #bbb;
    text-align: right;
}

