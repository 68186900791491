.-app-display-label {
    text-align: right;
    font-weight: bold;
}

@media (max-width: 768px) {
    .-app-display-label {
        text-align: left;
    }
}

/* White panes is Details pages. Every section should be wrapped in it.
   Use togeter with the Bootstrap
   Put <h2> inside such a pane
*/

.-app-display-section {
    background-color: rgba(248, 249, 250, 0.8);
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0 0 5px #ccc;
    border-radius: 5px;

    h2 {
        margin-top: 0px;
        margin-bottom: 10px;
    }
}
