.-app-number-input {
    max-width: 10em;
}

.-app-small-select {
    width: 10em;
}

.-app-medium-control {
    width: 25em;
}

.react-date-picker__wrapper {
    border: 1px solid #ced4da !important;
    border-radius: 0.375rem !important;
    background: white !important;
    padding: 0.375rem 0.75rem 0.375rem 0.75rem !important;
}

.-app-label-help {
    // position: relative;
    // top: 0.06rem;
    margin-left: 0.25rem;
}

.-app-h2-section-label-help {
    font-size: smaller;
    margin-left: 0.5rem;
}

// removed as it causes ugly white corners in search box on the index page
// .input-group {
//     background-color: #ffff;
// }

.-mko-react-select-control-bootstrap {
    border-radius: .375rem !important;
}