@import "/src/styles/_background.scss";

div.-app-login-form-main-wrapper {
    position: relative;

    img.-app-company-logo {
        margin-left: 12px;
        margin-top: 9px;
    }
}

div.-app-login-form.content-wrapper {
    flex-grow: 1;

    min-height: 100vh;
}
