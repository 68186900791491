@import "styles/variables.scss";

// $button-row-height: 72px;

// $total-height-to-substract: 179px + $navbar_height +$button-row-height;

// .-page-grid {
//     height: calc(100vh - #{$total-height-to-substract});
// }

.-page-action-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -8px;

    .-page-actions {
        flex-grow: 1;
    }

    
    .-page-help {
        margin-top: 8px;
    }

    .-page-search {
        margin-top: 8px;
        min-width: 10em;
    }
}

#popover-help {
    min-width: 50em;
}
