.-app-backgroundOperation-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 10000;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;

	&.opening {
		transition-property: opacity;
		transition-delay: 1s;
		transition-duration: 1s;
		opacity: 1;
	}

	&.quickOpening {
		transition-property: opacity;
		transition-duration: 0.2s;
		opacity: 0.999; // quickOpening continues opening, with transition on opacity, the second transition gets ignored unless we change the target value
	}

	&.open {
		opacity: 1;
	}

	&.closing {
		transition-property: opacity;
		transition-duration: 0.2s;
		opacity: 0;
	}

	&.closed {
		visibility: hidden;
		opacity: 0;
	}
	
}
