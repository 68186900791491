div.multipleAttachmentSelect {
    display: grid;
    grid-template-columns: fit-content(2em) minmax(10em, 40em) fit-content(20em);
    width: 100%;
    column-gap: 10px;
    row-gap: 12px;

    div.attachmentRow {
        div.attachmentNumber {
            align-self: center;
        }
        div.attachmentName {
            align-self: center;
        }
        div.attachmentRemove {
            align-self: center;
            justify-self: stretch;

            .btn {
                width: 100%;
            }
        }
    }

    div.progressBar {
        align-self: center;
    }
    div.attachmentAdd {
        align-self: center;
        justify-self: stretch;

        .btn {
            width: 100%;
        }

    }
}
