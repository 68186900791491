/* White pane - container for section in information pages, like about page
       <h2> can be put inside such a pane or outside 
    */
.-mko-white-info-pane {
    background-color: rgba(255, 255, 255, 0.8);
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
    box-shadow: 0 0 5px #ccc;
    border-radius: 5px;
    padding: 30px;
    font-size: 16px;
    /* adjustments when h2 is used inside pane */
    h2 {
        margin-top: -10px;
        margin-bottom: 20px;
    }
}
