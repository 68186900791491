.-app-background-holder {
    // note below that the second selector has a space

    &.-app-company-enexon, .-app-company-enexon & {
        background: url("../images/enexon_background.svg"), #f4f4f4;
        background-size: contain;
        background-attachment: fixed;
        background-position: bottom right;
        background-repeat: no-repeat;
    }

    &.-app-company-fega, .-app-company-fega & {
        background: url("../images/fega_background.svg"), #f4f4f4;
        background-size: cover;
        background-attachment: fixed;
        background-position: bottom right;
        background-repeat: no-repeat;
    }

    &.-app-company-ke, .-app-company-ke & {
        background: url("../images/ke_background.svg"), #f4f4f4;
        background-size: contain;
        background-attachment: fixed;
        background-position: bottom right;
        background-repeat: no-repeat;
    }
}
