// company-specific ajustments

$location_front_page_pane_background_enexon: #ff6400;
$location_front_page_pane_background_fega: #da251d;
$location_front_page_pane_background_ke: #84bd00;
$location_front_page_pane_background_common_hover: #6f6f6f;
$location_front_page_pane_background_common_active: darken(#6f6f6f, 10%);

$global_link_color: #6f6f6f;

$location_front_page_h1_color: $global_link_color;

div.-app-company-enexon {
    #content div.-mko-location-front-page {
        // font-family: $font_family_header_enexon;


        h1 {
            color: $location_front_page_h1_color;
        }

        div.-mko-lfp-panes-container {
            a.-mko-lfp-pane {
                background: $location_front_page_pane_background_enexon;

                &:hover {
                    background: $location_front_page_pane_background_common_hover;
                }

                &:active {
                    background: $location_front_page_pane_background_common_active;
                }
            }
        }
    }
}

div.-app-company-fega {
    #content div.-mko-location-front-page {
        // font-family: $font_family_header_fega;

        h1 {
            color: $location_front_page_h1_color;
        }

        div.-mko-lfp-panes-container {
            a.-mko-lfp-pane {
                background: $location_front_page_pane_background_fega;

                &:hover {
                    background: $location_front_page_pane_background_common_hover;
                }

                &:active {
                    background: $location_front_page_pane_background_common_active;
                }
            }
        }
    }
}

div.-app-company-ke {
    #content div.-mko-location-front-page {
       // font-family: $font_family_header_ke;

        h1 {
            color: $location_front_page_h1_color;
        }

        div.-mko-lfp-panes-container {
            a.-mko-lfp-pane {
                background: $location_front_page_pane_background_ke;

                &:hover {
                    background: $location_front_page_pane_background_common_hover;
                }

                &:active {
                    background: $location_front_page_pane_background_common_active;
                }
            }
        }
    }
}
