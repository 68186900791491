@import "styles/variables.scss";
@import "/src/styles/_background.scss";


div.-app-main-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#content {
    flex-grow: 1;
    min-height: calc(100vh - $navbar_height);
}
